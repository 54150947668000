import React, { useEffect, useCallback, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Container from '@mui/material/Container';
import { useGridApiRef  } from '@mui/x-data-grid';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import grey from '@mui/material/colors/grey';
import orange from '@mui/material/colors/orange';
import Nav from './Nav';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const theme = createTheme({
  palette: {
    primary: orange,
    secondary: grey,
    default: grey
  },
});

function Layout() {
  const params = useParams();

  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [activeProject, setActiveProject] = React.useState(null);
  const [projects, setProjects] = React.useState(null);
  const [organization, setOrganization] = React.useState(null);
  const [userSub, setUserSub] = React.useState(null);
  const [userData, setUserData] = React.useState(null);
  const [uploadingFiles, setUploadingFiles] = React.useState({});
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [runs, setRuns] = React.useState(null);
  const [reloadRuns, setReloadRuns] = React.useState(false);
  const [samples, setSamples] = React.useState(null);
  const [datasetStatusRef, setDatasetStatusRef] = React.useState({});
  const [loadStatuses, setLoadStatuses] = React.useState(true);

  const datasetsGridRef = useGridApiRef()

    const getStatus = useCallback((runId, accessToken, signal) => {
            const headers = new Headers({
                Authorization: `Bearer ${accessToken}`,
            });

            return fetch(
                        `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/sequencing_runs/${runId}?extra_fields=status`,
                        {
                            method: 'GET',
                            headers,
                            signal,
                        },
        )
            .then((res) => res.json())
            .then((response) => {
                setDatasetStatusRef(existingRef => {
                    return {
                        ...existingRef,
                        [response.data.id]: response.data.attributes.status
                    }
                })

                datasetsGridRef.current.updateRows([{id: response.data.id, status: response.data.attributes.status}])
            })
            .catch((error) => console.error(error));

    })

    const loadChunkOfStatuses = useCallback((signal) => {
        if (!runs) {
            return
        }

        let runChunks = []
        const chunkSize = 10
        for (let i = 0; i < runs.length; i += chunkSize) {
            const run = runs[i]
            if (datasetStatusRef[run.id] || (!datasetsGridRef.current.getRowElement(run.id))) {
                continue
            }

            const chunk = runs.slice(i, i + chunkSize);
            if (!chunk.length) {
                continue
            }

            runChunks.push(chunk)
            if (runChunks.length > 1) {
                break
            } 
        }

        if (!runChunks.length) {
            console.log("Nothing to get")
        }

        return getAccessTokenSilently()
            .then(accessToken => {
                const promise = Promise.resolve()
                runChunks.reduce((accPromise, chunk) => {
                    return accPromise.then(
                        () => {
                            return Promise.all(chunk.map(run => getStatus(run.id, accessToken)))
                            .then(() => {
                                return new Promise(resolve => setTimeout(resolve, 1000))
                            })
                        }
                    )
                }, promise)

                return promise
            })
            .catch(console.error)
    })

    useEffect(() => {
        if (!runs || params.sampleId) {
            return
        }

        if (!loadStatuses) {
            return
        }

        const controller = new AbortController();
        const { signal } = controller;

        setTimeout(
            () => {
                loadChunkOfStatuses(signal)
                    .then(() => setLoadStatuses(false))
                    .catch(() => setLoadStatuses(false))
            }, 1000
        )

        return () => controller.abort()
    }, [runs, loadStatuses]);

    useEffect(() => {
        if (!user || userSub) {
            return
        }

        setUserSub(user.sub)
    }, [user, userSub])

    const triggerAnalysis = useCallback(concatenatedFastqgzData => { // eslint-disable-line react-hooks/exhaustive-deps
        const concatenatedFastqgzID = concatenatedFastqgzData.id
        return getAccessTokenSilently().then(accessToken => {
            const headers = new Headers({
              Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
            });

            let attributes = {}
            if (concatenatedFastqgzData.location_r2_signed_upload_url === null) {
                attributes['rapid_turnaround'] = true
            } else {
                attributes['generate_amr_predictions'] = true
            }


          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/concatenated_fastqgzs/${concatenatedFastqgzID}/analyze`,
            {
              method: 'PUT',
              headers,
                body: JSON.stringify(
                    {
                        data: {
                            type: "analyze",
                            attributes: attributes
                        }

                    }
                )
            },
        )

        })
      .then((res) => {
          if(!res.ok) throw new Error(res.status);
        setUploadingFiles((existingUploadingFiles) => {
          const newUploadingFiles = {}
          for (const uploadingConcatenatedFastqgzID in existingUploadingFiles) {
              if (parseInt(uploadingConcatenatedFastqgzID) !== concatenatedFastqgzID) {
                  newUploadingFiles[uploadingConcatenatedFastqgzID] = existingUploadingFiles[uploadingConcatenatedFastqgzID]
              }

          }
            return newUploadingFiles
        });
      })
      .catch(
          (error) => {
            console.error(error)
          }
        );
    }, [getAccessTokenSilently])

    const uploadFile = useCallback((concatenatedFastqgzData, file, r2File) => {
        setUploadingFiles((existingUploadingFiles) => {
            const newUploadingFiles = {}
            for (const [existingConcatenatedFastqgzID, existingConcatenatedFastqgzData] of Object.entries(existingUploadingFiles)) {
                newUploadingFiles[existingConcatenatedFastqgzID] = existingConcatenatedFastqgzData
            }

            newUploadingFiles[concatenatedFastqgzData.id] = concatenatedFastqgzData
            return newUploadingFiles
        });

        setSnackbarOpen(true)

        let promises = [
            fetch(
                concatenatedFastqgzData.attributes.location_signed_upload_url,
                {
                  method: 'PUT',
                    body: file
                },
            )
        ]

        if (r2File) {
            promises.push(
                fetch(
                    concatenatedFastqgzData.attributes.location_r2_signed_upload_url,
                    {
                      method: 'PUT',
                        body: r2File
                    },
                )
            )
        }

        return Promise.all(promises).then((resArray) => {
              if (!resArray.every(res => res.ok)) throw new Error(resArray);
              return triggerAnalysis(concatenatedFastqgzData)
          })
          .catch(
              (error) => {
                console.error(error)
              }
            );
    }, [triggerAnalysis]);


  const handleActiveProjectChange = (event) => {
    const newActiveProject = projects.find((project) => project.id === event.target.value);
    setActiveProject(newActiveProject);
    setReloadRuns(true)
  };

  useEffect(() => {
    if (!userSub) {
      return;
    }

    getAccessTokenSilently()
    .then(accessToken => {
      const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
      });

      return fetch(
        `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/users/${userSub}`,
        {
          method: 'GET',
          headers,
        },
      );
    })
    .then((res) => res.json())
    .then((userData) => setUserData(userData))
    .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, userSub])

  useEffect(() => {
    if (!activeProject || params.sampleId) {
      return;
    }

    if (reloadRuns) {
        setRuns(null);
        setReloadRuns(false);
        return
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
          `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/projects/${activeProject.id}/sequencing_runs`,
          {
            method: 'GET',
            headers,
          },
        )
      })
      .then((res) => res.json())
      .then((response) => {
        const allRuns = response.data.sort((a,b) => {
            return b.id - a.id
        })

        setRuns(allRuns);
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, activeProject, reloadRuns, setReloadRuns]);

  useEffect(() => {
    if (!userData) {
      return
    }
    const { organization_id } = userData.data.attributes;

    getAccessTokenSilently()
    .then(accessToken => {
      const organizations = {
        1: {'attributes': {'name': 'Day Zero Diagnostics'}},
        2: {'attributes': {'name': 'Guy\'s and St Thomas\' NHS Foundation Trust'}}
      }

        setOrganization(
            organizations[organization_id]
        );
    }); 
  }, [isAuthenticated, getAccessTokenSilently, userData])

  useEffect(() => {
    if (!userData) {
      return
    }
    const { organization_id } = userData.data.attributes;

    getAccessTokenSilently()
    .then(accessToken => {
      const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
      });

      return fetch(
        `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/organizations/${organization_id}/projects`,
        {
          method: 'GET',
          headers,
        },
      );
    })
    .then((res) => res.json())
    .then((response) => {
      setActiveProject(response.data[0]);
      setProjects(response.data);
    })
    .catch((error) => console.error(error));
  }, [userData, getAccessTokenSilently]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;

        }

        setSnackbarOpen(false);
    }

  let snackbarContent = null
  if (Object.keys(uploadingFiles).length === 0) {
      snackbarContent = (
          <Alert
          severity="success"
          variant="filled"
          sx={{ width: '100%'  }}
          >
            All sequencing files successfully uploaded
          </Alert>
      )
  } else {
      snackbarContent = (
        <SnackbarContent 
            message={`Uploading sequencing data for ${Object.keys(uploadingFiles).length} sample${Object.keys(uploadingFiles).length !== 1 ? 's' : ''}`}
            action={
                (
              <CircularProgress
                    sx={{marginRight: "10px"}}
                    size={15}
                  />
                )
            }
        />
      )
  }

  const datasetsGridRows = useMemo(() => {
      if (!runs) {
          return null
      }

        return runs.map(run => {
            return {
                id: run.id,
                name: run.attributes.name,
                platform: run.attributes.sequencing_platform || run.attributes.platform,
                status: run.attributes.status,
                sequencer_name: run.attributes.sequencer_name,
                sequencer_sn: run.attributes.minion_sn,
                flowcell: run.attributes.flowcell_name,
                barcode_kit: run.attributes.barcoding_kit,
                library_prep_kit: run.attributes.library_prep_kit,
                created_at: new Date(run.attributes.created_at)
            }
        })

    }, [runs])

  return (
    <ThemeProvider theme={theme}>
      <Nav
        projects={projects}
        organization={organization}
        activeProject={activeProject}
        onActiveProjectChange={handleActiveProjectChange}
        userData={userData}
      samples={samples}
      setSamples={setSamples}
      datasetsGridRef={datasetsGridRef}
      />
      <Container maxWidth={false} disableGutters sx={{ overflowX: 'hidden' }}>
        <Outlet context={
            {
                activeProject,
                userData,
                uploadFile,
                uploadingFilesCt: Object.keys(uploadingFiles).length,
                runs,
                reloadRuns,
                setReloadRuns,
                samples,
                setSamples,
                datasetsGridRef,
                datasetsGridRows,
                datasetStatusRef,
                setLoadStatuses
            }
        } />
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={Object.keys(uploadingFiles).length === 0 ? 5000 : null}      
            open={snackbarOpen}
            onClose={handleSnackbarClose}
        >
        {snackbarContent}
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
}

export default Layout;
